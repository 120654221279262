import React from 'react'
import PropTypes from 'prop-types'
import Nav from './nav'
import Footer from './footer'
import Seo from './seo'

const Layout = ({ title, subtitle, children, seoTitle }) => {
  return (
    <>
      <h1 className="site-heading text-center text-white d-none d-md-block">
        <span className="site-heading-lower">{title}</span>
        <span className="site-heading-upper text-primary mb-3">{subtitle}</span>
      </h1>

      <Seo title={!seoTitle ? title : seoTitle} />
      <Nav />

      <main>{children}</main>

      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
