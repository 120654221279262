import React from 'react'

const PersonCard = ({ data }) => (
  <section className="page-section">
    <div className="container">
      <div className="product-item">
        <div className="product-item-title d-flex">
          <div className="bg-faded p-5 d-flex mr-auto rounded">
            <h2 className="section-heading mb-0">
              <span className="section-heading-upper">{data.name.prefix}</span>
              <span className="section-heading-lower">{data.name.first_name} {data.name.last_name}</span>
              <span className="section-heading-upper" style={{ textAlign: 'right' }}>{data.name.decoration}</span>
            </h2>
          </div>
        </div>
        <img className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0" src={data.image.publicURL} alt="" style={{ backgroundColor: 'red' }} />
        <div className="product-item-description d-flex ml-auto">
          <div className="bg-faded p-5 rounded">
            <p className="mb-0">{data.bio}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default PersonCard