import React from 'react'
import Layout from '../components/layout'
import { useStaticQuery, graphql } from 'gatsby'
import PersonCard from '../components/personcard'
import ReactMarkdown from 'react-markdown'




const AboutPage = () => {
  const data = useStaticQuery(aboutQuery)

  return (
    <>
      <Layout title={'About'} subtitle={'HRBC'}>
        {data.allStrapiPerson.nodes.map((person, index) => (
          <PersonCard key={index} data={person} />
        ))}
        <section id="beliefs" className="page-section cta">
          <div className="container">
            <div className="row">
              <div className="col-xl-9 mx-auto">
                <div className="cta-inner text-center rounded remark-content">
                  <ReactMarkdown source={data.strapiAboutPage.body} />
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

export default AboutPage



const aboutQuery = graphql`{
  allStrapiPerson {
    nodes {
      id
      name {
        first_name
        last_name
        prefix
        decoration
      }
      bio
      image { publicURL }
    }
  }
  strapiAboutPage {
    body
    updated_at
  }
}
`